import { find, get, orderBy } from 'lodash'
import chance from 'chance'
import modalProps from '../modalProps'
import selectAreas from '../../../../../Selectors/selectAreas'
import selectAreaGroups from '../../../../../Selectors/selectAreaGroups'
import { setCostSheet, setCostSheetField } from '../../../../../State/order'
import addItem from '../../../Services/addItem'

export default () => (dispatch, getState) => {

  const state = getState()

  const { materialTypes, materialRanges } = state.core

  const areas = selectAreas(state)
  const areaGroups = selectAreaGroups(state)
  
  const { cheat_sheets: cheatSheets } = state.screens.CostSheet.order

  const { fields } = state.screens.CostSheet.Areas.SetupModal

  // Cheat sheet

  if (fields.type === 'cheat-sheet') {

    const cheatSheet = find(cheatSheets, row => row.id === fields.cheatSheetId)
    
    const materialRange = find(materialRanges, row => row.id === fields.materialRangeId)
    const rangeVariant = find(materialRange.range_variants, row => row.id === fields.rangeVariantId)

    dispatch(setCostSheet({
      ...cheatSheet,
      cost_sheet_areas: cheatSheet.cost_sheet_areas.map(row => ({
        ...row,
        id: null,
        range_variant_id: rangeVariant.id,
        guid: chance().guid(),
        range_variant: rangeVariant,
      })),
      cost_sheet_materials: cheatSheet.cost_sheet_materials.map(row => {
        const $row = {
          ...row,
          id: null,
          guid: chance().guid(),
        }
        if (row.material_range_id === materialRange.id) {
          $row.range_variant_id = rangeVariant.id
          $row.range_variant = rangeVariant
        }
        return $row
      }),
      cost_sheet_expenses: cheatSheet.cost_sheet_expenses.map(row => ({
        ...row,
        id: null,
        guid: chance().guid(),
      })),
      cost_sheet_quotes: cheatSheet.cost_sheet_quotes.map(row => ({
        ...row,
        id: null,
        guid: chance().guid(),
      })),
    }))

    modalProps().onClose()

    return

  }

  // Areas / group

  const materialRange = find(materialRanges, row => row.id === fields.materialRangeId)
  const rangeVariant = find(get(materialRange, 'range_variants', []), row => row.id === fields.rangeVariantId)

  const items = []

  if (fields.type === 'areas') {

    const materialType = find(materialTypes, row => row.id === fields.materialTypeId)

    dispatch(setCostSheetField({ field: 'material_type_id', value: materialType.id }))

    fields.areaIds.forEach(areaId => {

      const area = find(areas, row => row.id === areaId)

      const item = {
        area_id: area.id,
        material_type_id: materialType.id,
        material_range_id: get(materialRange, 'id'),
        range_variant_id: get(rangeVariant, 'id'),
        area,
        material_type: materialType,
        material_range: materialRange,
        range_variant: rangeVariant,
      }

      if (materialType.usage_key === 'dimensions') {
        const areaMaterialType = find(area.area_material_types, row => row.material_type_id === materialType.id)
        item.dimensions = get(areaMaterialType, 'dimensions')
        item.square_metres = get(areaMaterialType, 'square_metres')
      }

      if (materialType.usage_key === 'square-metres' && materialRange) {
        item.square_metres = area.square_metres_gross
        if (materialRange.wastage_perc && area.square_metres) {
          item.square_metres = area.square_metres + ((area.square_metres / 100) * materialRange.wastage_perc)
        }
      }

      items.push(item)

    })

  }

  if (fields.type === 'area-group') {

    const areaGroup = find(areaGroups, row => row.id === fields.areaGroupId)
    const materialType = find(materialTypes, row => row.id === areaGroup.material_type_id)

    dispatch(setCostSheetField({ field: 'material_type_id', value: materialType.id }))
    dispatch(setCostSheetField({ field: 'area_group_id', value: areaGroup.id }))
    dispatch(setCostSheetField({ field: 'quote_areas', value: fields.quoteAreas }))

    areaGroup.area_material_types.forEach(areaMaterialType => {
      const area = find(areas, row => row.id === areaMaterialType.area_id)
      items.push({
        area_id: area.id,
        material_type_id: materialType.id,
        material_range_id: materialRange.id,
        range_variant_id: rangeVariant.id,
        dimensions: areaMaterialType.dimensions,
        square_metres: areaMaterialType.square_metres,
        notes: areaMaterialType.notes,
        area,
        material_type: materialType,
        material_range: materialRange,
        range_variant: rangeVariant,
      })
    })

  }

  orderBy(items, 'area.order_no').forEach(item => dispatch(addItem(item)))

  modalProps().onClose()

}
