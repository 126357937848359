import { createSelector } from 'reselect'
import { find, get } from 'lodash'
import selectAreaGroups from '../../../../../Selectors/selectAreaGroups'
import selectMaterialRanges from '../../../../../Selectors/selectMaterialRanges'

const selectCheatSheets = state => state.screens.CostSheet.order.cheat_sheets
const selectFields = state => state.screens.CostSheet.Areas.SetupModal.fields

export default createSelector(
  selectAreaGroups,
  selectMaterialRanges,
  selectCheatSheets,
  selectFields,
  (areaGroups, materialRanges, cheatSheets, fields) => {

    if (fields.type === 'cheat-sheet') {

      const cheatSheet = find(cheatSheets, row => row.id === fields.cheatSheetId)

      const materialRange = get(cheatSheet, 'cost_sheet_areas[0].material_range')

      if (!materialRange) return []

      return [materialRange]

    }
    
    if (fields.type === 'areas') {

      if (!fields.materialTypeId) return []

      return materialRanges.filter(row => row.material_type_id === fields.materialTypeId)
      
    }
    
    if (fields.type === 'area-group') {

      const areaGroup = find(areaGroups, row => row.id === fields.areaGroupId)

      if (!areaGroup) return []

      return materialRanges.filter(row => row.material_type_id === areaGroup.material_type_id)

    }

    return []

  },
)
