import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputField, InputNumberField, SelectField } from 'redux-form-antd-fields';

const labelColSpan = 5;
const wrapperColSpan = 16;

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    managers: PropTypes.array.isRequired,
  }

  render () {

    const {
      submitting,
      managers,
    } = this.props;

    const managerOptions = managers.map(user => ({ value: user.id, label: `${user.first_name} ${user.last_name}` }))

    return (
      
      <React.Fragment>

        <Field
          name="name"
          label="Name"
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          name="manager_id"
          label="Manager"
          component={SelectField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          options={managerOptions}
        />

        <Field
          name="address_line_1"
          label="Address"
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          placeholder='Line 1'
        />

        <Field
          name="address_line_2"
          label=" "
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          placeholder='Line 2'
          colon={false}
        />

        <Field
          name="address_line_3"
          label=" "
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          placeholder='Line 3'
          colon={false}
        />

        <Field
          name="town"
          label=" "
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          placeholder='Town'
          colon={false}
        />

        <Field
          name="postcode"
          label=" "
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          placeholder='Postcode'
          colon={false}
        />
              
        <Field
          name="account_ref"
          label="Account Ref"
          component={InputField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          name="travel_rate"
          label="Travel"
          precision={2}
          component={InputNumberField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 9 }}
        />

      </React.Fragment>

    )
  }
}