import { connect } from 'react-redux';

import Component from './Component';

const mapStateToProps = (state) => ({
  managers: state.core.users
});

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Component);